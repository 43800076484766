import { Grid, makeStyles } from "@material-ui/core";
import asqmeAssistantLogo from 'assets/images/asqme-assistant.svg';

const useStyles = makeStyles((theme) => ({
  asqMeAssistantContainer: {
    width: 357,
  },
  asqMeAssistantHeader: {
    '& img': {
      position: 'relative',
      bottom: 2,
    },
    '& span': {
      fontSize: 14,
      lineHeight: '19.07px',
      color: '#666666',
    },
    '& span.text1': {
      fontFamily: 'Figtree-Bold',
    },
    '& span.text2': {
      fontFamily: 'Figtree-Regular',
    },
  },
  asqMeAssistantMessage: {
    padding: '4px 20px !important',
    '& p': {
      fontSize: 16,
      lineHeight: '21.79px',
    },
  },
}));

const AsqMeAssistantMessage = (props) => {
  const { message } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      direction='column'
      spacing={1}
      className={classes.asqMeAssistantContainer}
    >
      <Grid
        container
        item
        direction='row'
        xs={12}
        spacing={1}
        className={classes.asqMeAssistantHeader}
      >
        <Grid item>
          <img src={asqmeAssistantLogo} alt='AsqMe Assistant Logo' />
        </Grid>
        <Grid item>
          <span className='text1'>AsqMe Assistant</span>
        </Grid>
        <Grid item>
          <span className='text2'>{'(only visible to you)'}</span>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.asqMeAssistantMessage}>
        <p>{message}</p>
      </Grid>
    </Grid>
  )
};

export default AsqMeAssistantMessage;
