import { Fragment, useEffect, useCallback, useState, useMemo, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import { useFormik } from 'formik';
import { Fade } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import {
  AttachmentActionIcon,
  FirstDraftActionIcon,
  CircleErrorIcon,
  GoldStarIcon,
  ProgressStatusIcon,
  StarIcon as OutlinedStarIcon,
  DoublePaperIcon,
  PaperPlaneIcon,
  RevertIcon,
  BottomArrowIcon,
  RightArrowIcon,
  TranslateIcon,
} from 'components/Icons';
import useUpload from 'hooks/upload';
import { queryCreatorTypes, getQuestionDetails, getCopilotAnswer } from 'store/creator';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { displayCustomerName, displayCustomerMessage, displayLastActionTime } from './utils';
import { thumbnailVideoUrl } from './constants';
import { drawerStyles as styles } from './styles';
import { defaultAlertError } from 'utils/common/constants';
import { slideoutTransitionDelay } from 'config/ui';
import { ClipsCloseButton, ClipsPlayButton } from 'components/Clips/ClipsButtons';
import { ClipsVideoPlayerDialog } from 'components/Clips/ClipsVideoPlayerDialog';
import { TimeSpan } from 'utils/time-span';
import QuestionPassButton from './QuestionPassButton';
import AsqMeAssistantMessage from './AsqMeAssistantMessage';
import { getQuestionPassReasonLabel } from 'utils/common/constants';

const useStyles = makeStyles(styles);

/**
 * Get message from assistant. Might return null if there is nothing to say.
 */
function getAssistantMsg(user, questionSess) {
  // No answer means we have nothing to say
  if (!questionSess.first_draft_answer) return null;

  // No clips
  if (!user.clips_enabled)
    return `Hi ${user.display_name}. I found the answer below from ChatGPT. If you want to use your own content to answer, just connect your YouTube channel. I will even find your relevant video clips and cue them up to just the right spot so you can attach them to your answer and get more views.`;

  // No video
  if (!questionSess.clipsCreatorItem)
    return `I didn’t find an answer in your YouTube channel but got the answer below from ChatGPT.`;

  // Video but no answer
  if (!questionSess.clips_is_content_driven_answer)
    return `I didn’t find an exact answer in your YouTube channel but did find a related video and attached it below. If it helps, I also included an answer from ChatGPT.`;

  // Video with timestamp
  if (questionSess.clips_timestamp)
    return `I found the answer and video clip below in your YouTube channel. I cued up the video to the answer.`;

  // Video with no timestamp
  return `I found the answer and video below in your YouTube channel.`;
}

const QuestionSessionDrawer = ({
  alert,
  sendAnswerIsLoading,
  mobileType,
  open,
  selectedQuestion,
  onClose,
  user,
  onSendAnswerToAudience,
  onToggleFavoriteQuestion,
  onQuestionPassClick,
}) => {
  const classes = useStyles();
  const { blue, grey } = useTheme();

  const queryClient = useQueryClient();

  const textAreaRef = useRef(null);
  const [firstDraftInputOpen, setFirstDraftInputOpen] = useState(false);
  const [firstDraftAnswerClicked, setFirstDraftAnswerClicked] = useState(false);

  const [clipsVideo, setClipsVideo] = useState(null);
  const [useClip, setUseClip] = useState(true);

  const { getFieldProps, handleBlur, handleChange, handleSubmit, values, resetForm } = useFormik({
    initialValues: {
      creator_message: '',
      firstDraft_prompt: '',
      attachment_image_name: '',
      attachment_image_url: '',
      attachment_video_name: '',
      attachment_video_url: '',
    },
  });

  const { data: questionSession, isFetching } = useQuery({
    queryKey: [queryCreatorTypes.getQuestionDetails, selectedQuestion?.session_tracking_code],
    queryFn: getQuestionDetails,
    enabled: !!selectedQuestion,
    onSuccess: (data) => {
      const firstDraftAnswer = data[data.length - 1].first_draft_answer || '';
      if (firstDraftAnswer.length) {
        handleChange({
          target: {
            name: 'creator_message',
            value: firstDraftAnswer,
          },
        });
      }
    },
  });

  // copilot answer api call
  const {
    mutate: onGetCopilotAnswer,
    reset: resetGetCopilotAnswer,
    data: copilotAnswer,
    isLoading: copilotAnswerIsLoading,
  } = useMutation({
    mutationFn: getCopilotAnswer,
    onSuccess: (data) => {
      const { choices: [{ text = '' }] = [{}] } = data || {};

      handleChange({
        target: {
          name: 'firstDraft_prompt',
          value: text,
        },
      });
    },
    onError: (err) => {
      alert.error(null, { ...defaultAlertError, offsetError: true });
    },
  });

  // remove selected question details from cache when drawer is closed
  useEffect(() => {
    if (!open) {
      textAreaRef.current = null;
      setFirstDraftInputOpen(false);
      setFirstDraftAnswerClicked(false);
      resetForm();
      resetGetCopilotAnswer();
      queryClient.removeQueries({ queryKey: [queryCreatorTypes.getQuestionDetails] });
    }
  }, [open]);

  const handleTrimOnBlur = useCallback(
    (e) => {
      e.target.value = e.target.value.trim();
      handleChange(e);
      handleBlur(e);
    },
    [handleChange, handleBlur],
  );

  const handleFormImageChange = useCallback((formData) => {
    const isVideoUploaded = formData.fileType.split('/')[0] === 'video';
    // image related updates
    handleChange({
      target: {
        name: 'attachment_image_url',
        value: isVideoUploaded ? '' : formData.encodedFile,
      },
    });
    handleChange({
      target: {
        name: 'attachment_image_name',
        value: isVideoUploaded ? '' : formData.fileName,
      },
    });
    // video related updates
    handleChange({
      target: {
        name: 'attachment_video_url',
        value: isVideoUploaded ? formData.encodedFile : '',
      },
    });
    handleChange({
      target: {
        name: 'attachment_video_name',
        value: isVideoUploaded ? formData.fileName : '',
      },
    });
  }, []);

  const removeFormAttachments = useCallback(() => {
    handleChange({
      target: {
        name: 'attachment_image_url',
        value: '',
      },
    });
    handleChange({
      target: {
        name: 'attachment_image_name',
        value: '',
      },
    });
    handleChange({
      target: {
        name: 'attachment_video_url',
        value: '',
      },
    });
    handleChange({
      target: {
        name: 'attachment_video_name',
        value: '',
      },
    });
  }, []);

  const {
    getRootProps: rootAttachmentProps,
    getInputProps: inputAttachmentProps,
    uploadIsLoading: attachmentUploadIsLoading,
  } = useUpload(handleFormImageChange, { disabled: false, videoAccept: true, offsetError: true });

  const canSubmitAnswer =
    firstDraftAnswerClicked && values.creator_message.length && !attachmentUploadIsLoading;

  const {
    originalQuestion,
    currQuestionSession,
    isLastQuestionAnswered,
    firstDraftAnswer,
    lastQuestionTrackingCode,
    isTranslated,
    translationDescription,
    asqMeAssistantMessage,
  } = useMemo(() => {
    if (!questionSession)
      return {
        originalQuestion: '',
        isLastQuestionAnswered: true,
        firstDraftAnswer: '',
        lastQuestionTrackingCode: '',
        isTranslated: false,
        translationDescription: '',
      };

    const originalQuestion = questionSession[0].customer_message || '';
    const currQuestionSession = questionSession[questionSession.length - 1];
    const isLastQuestionAnswered = !!questionSession.length && currQuestionSession.answered;
    const firstDraftAnswer = currQuestionSession.first_draft_answer || '';
    const lastQuestionTrackingCode = currQuestionSession.question_tracking_code;
    const isTranslated = questionSession[0].is_translated;
    const translationDescription = questionSession[0].google_translation_message;

    let asqMeAssistantMessage = getAssistantMsg(user, currQuestionSession);

    return {
      originalQuestion,
      isLastQuestionAnswered,
      firstDraftAnswer,
      lastQuestionTrackingCode,
      isTranslated,
      translationDescription,
      asqMeAssistantMessage,
      currQuestionSession,
    };
  }, [questionSession]);

  const showClipsVideo = (session) => {
    let timestamp = null;

    if (session.clips_timestamp) {
      timestamp = new TimeSpan(session.clips_timestamp);
    }

    const video = {
      id: session.clipsCreatorItem?.external_id,
      title: session.clipsCreatorItem?.title,
      timestamp,
    };

    setClipsVideo(video);
  };

  function handleAnswerTextClick() {
    setFirstDraftAnswerClicked(true);
  }

  function handleAnswerTextInput() {
    setFirstDraftAnswerClicked(true);
  }

  function handleClearMsg() {
    setFirstDraftAnswerClicked(true);
    handleChange({
      target: {
        name: 'creator_message',
        value: '',
      },
    });
    textAreaRef.current.focus();
  }

  const StarIcon = selectedQuestion?.is_favorite ? GoldStarIcon : OutlinedStarIcon;

  const FirstDraftSelectIcon = !!copilotAnswer ? BottomArrowIcon : RightArrowIcon;
  const firstDraftSelectProps = {
    onClick: () => {
      if (!values.firstDraft_prompt.length) return;
      if (!copilotAnswer)
        onGetCopilotAnswer({
          questionTrackingCode: lastQuestionTrackingCode,
          prompt: values.firstDraft_prompt,
        });
      else {
        setFirstDraftInputOpen(false);
        resetGetCopilotAnswer();
        handleChange({
          target: {
            name: 'creator_message',
            value: values.creator_message.length
              ? `${values.creator_message}\n\n${values.firstDraft_prompt}`
              : values.firstDraft_prompt,
          },
        });
        handleChange({
          target: {
            name: 'firstDraft_prompt',
            value: '',
          },
        });
      }
    },
    fillColor: !!copilotAnswer || values.firstDraft_prompt ? blue : grey.A700,
    className: `${classes.actionButton} `,
  };

  return (
    <>
      <Drawer
        classes={{
          root: classes.drawer,
          paper: `${classes.paper} ${mobileType ? 'shrink' : ''}`,
          paperAnchorTop: classes.paper,
        }}
        transitionDuration={400}
        anchor={mobileType ? 'top' : 'right'}
        open={open}
        onClose={onClose}
        ModalProps={{
          disableEnforceFocus: true, // Disable MUI's automatic focus management
        }}
      >
        {(isFetching || sendAnswerIsLoading) && (
          <Grid container justifyContent='center' alignItems='center' className={classes.backdrop}>
            <Grid item>
              <CircularProgress color='inherit' thickness={3} size={70} />
            </Grid>
          </Grid>
        )}
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          className={classes.drawerContainer}
        >
          {/* header */}
          <Grid item container alignItems='center' className={classes.headerContainer}>
            {/* question original title */}
            <Grid item sm={9} xs={8}>
              <div className={classes.customerQuestion}>
                {displayCustomerMessage(originalQuestion)}
              </div>
            </Grid>
            {/* actions */}
            <Grid item sm xs>
              <Grid container justifyContent='flex-end' spacing={2}>
                {selectedQuestion && (!selectedQuestion.last_question_answered || selectedQuestion.is_passed) && (
                  <Grid item>
                    <QuestionPassButton
                      isPassed={selectedQuestion.is_passed}
                      extraClassName={classes.actionHeaderIcon}
                      onClick={() => {
                        if (!selectedQuestion) return;
                        onQuestionPassClick({ id: selectedQuestion.id, payment_mode: selectedQuestion.payment_mode });
                      }}
                    />
                  </Grid>
                )}
                <Grid item>
                  <StarIcon
                    width={22}
                    height={22}
                    strokeColor={selectedQuestion?.is_favorite ? undefined : grey.A700}
                    className={`${classes.actionHeaderIcon} ${
                      selectedQuestion?.is_hidden ? 'notAllowed' : ''
                    }`}
                    onClick={() => {
                      if (selectedQuestion?.is_hidden) return;
                      onToggleFavoriteQuestion({
                        id: selectedQuestion?.id,
                        is_favorite: !selectedQuestion?.is_favorite,
                      });
                    }}
                  />
                </Grid>
                <Grid item>
                  <CircleErrorIcon
                    width={22}
                    height={22}
                    fillColor={grey.A700}
                    className={classes.actionHeaderIcon}
                    onClick={onClose}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* translation text */}
          {isTranslated && !!translationDescription && (
            <Grid item container className={classes.translationContainer}>
              <Grid item>
                <TranslateIcon className={classes.tranlationIcon} />
              </Grid>
              <Grid item>
                <div className={classes.tranlationText}>{translationDescription}</div>
              </Grid>
            </Grid>
          )}
          {/* content */}
          <Grid item container className={classes.contentOuterContainer}>
            <div className={classes.contentInnerContainer}>
              {/* question/asnwer session */}
              {(questionSession || []).map((qs, idx) => {
                const progressBulletsProps = [
                  'firstBulletColor',
                  'secondBulletColor',
                  'thirdBulletColor',
                ]
                  .slice(0, idx + 1)
                  .reduce((obj, key) => {
                    obj[key] = blue;
                    return obj;
                  }, {});

                return (
                  <Fragment key={idx}>
                    {/* question */}
                    <Grid container className={classes.questionContainer}>
                      <Grid item className={classes.questionInnerContainer}>
                        {/* name/date */}
                        <Grid container justifyContent='space-between' alignItems='center'>
                          <Grid item>
                            <div className={classes.customerName}>
                              {displayCustomerName(selectedQuestion)}
                            </div>
                          </Grid>
                          <Grid item>
                            <div className={classes.questionDate}>
                              {displayLastActionTime(qs.created_at)}
                            </div>
                          </Grid>
                        </Grid>
                        {/* question title */}
                        <Grid container className={classes.questionTitleContainer}>
                          <Grid item xs={12}>
                            <div className={classes.customerMessage}>{qs.customer_message}</div>
                          </Grid>
                          {(!!qs.customer_attachment_image_url ||
                            !!qs.customer_attachment_video_url) && (
                            <Grid item className={classes.customerAttachmentContainer}>
                              <a
                                href={
                                  qs.customer_attachment_image_url ||
                                  qs.customer_attachment_video_url
                                }
                                target='_blank'
                                className={classes.customerAttachment}
                                style={{
                                  backgroundImage: `url(${
                                    qs.customer_attachment_image_url || thumbnailVideoUrl
                                  })`,
                                  backgroundPosition: 'center',
                                  backgroundSize: 'cover',
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* AsqMe assistant */}
                    {!qs.answered && asqMeAssistantMessage && (
                      <AsqMeAssistantMessage message={asqMeAssistantMessage} />
                    )}
                    {selectedQuestion?.is_passed && (
                      <AsqMeAssistantMessage message={`You passed on this question for the following reason: ${getQuestionPassReasonLabel(selectedQuestion.pass_reason)}. You may still respond to this question by entering your answer below.`} />
                    )}

                    {/* answer */}
                    {qs.answered ? (
                      <>
                        <Grid container className={classes.answerContainer}>
                          {/* user logo */}
                          <Grid item xs={2}>
                            <Grid container justifyContent='flex-end'>
                              <Grid item>
                                <div className={classes.logoPlaceholder}>
                                  <img src={user.logo_image} alt='profile' />
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* user answer section */}
                          <Grid item xs>
                            {/* progress */}
                            <Grid container justifyContent='space-between' alignItems='center'>
                              <Grid item>
                                <ProgressStatusIcon {...progressBulletsProps} />
                              </Grid>
                              <Grid item>
                                <div className={classes.questionDate}>
                                  {displayLastActionTime(qs.answer?.created_at)}
                                </div>
                              </Grid>
                            </Grid>
                            {/* answer content */}
                            <Grid container className={classes.answerTitleContainer}>
                              {/* text/attachments */}
                              <Grid item xs={12}>
                                <div className={classes.answerMessage}>{qs.answer?.message}</div>
                                <Grid
                                  container
                                  spacing={1}
                                  className={classes.customerAttachmentContainer}
                                >
                                  {qs.answer?.attachments?.image_urls.map((url) => (
                                    <Grid item key={url}>
                                      <a
                                        href={url}
                                        target='_blank'
                                        className={classes.customerAttachment}
                                        style={{
                                          backgroundImage: `url(${url})`,
                                          backgroundPosition: 'center',
                                          backgroundSize: 'cover',
                                        }}
                                      />
                                    </Grid>
                                  ))}
                                  {qs.answer?.attachments?.video_urls.map((url) => (
                                    <Grid item key={url}>
                                      <a
                                        href={url}
                                        target='_blank'
                                        className={classes.customerAttachment}
                                        style={{
                                          backgroundImage: `url(${thumbnailVideoUrl})`,
                                          backgroundPosition: 'center',
                                          backgroundSize: 'cover',
                                        }}
                                      />
                                    </Grid>
                                  ))}
                                  {/* copy icon */}
                                  <Grid item xs>
                                    <Grid container justifyContent='flex-end'>
                                      <Grid item>
                                        <CopyToClipboard
                                          text={`${qs.answer?.message}${
                                            qs.answer?.show_clips && qs.clips_url != null
                                              ? `\n\nHere’s a video: ${qs.clips_url}`
                                              : ''
                                          }`}
                                          onCopy={() => {
                                            alert.success(null, {
                                              title: 'All set',
                                              body: 'Answer copied to clipboard.',
                                              timeout: 1000 + slideoutTransitionDelay,
                                              offsetError: true,
                                            });
                                          }}
                                        >
                                          <DoublePaperIcon
                                            width={32}
                                            height={32}
                                            className={classes.menuIcon}
                                          />
                                        </CopyToClipboard>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {qs.answer?.show_clips && qs.clipsCreatorItem != null && (
                          <Grid item container justifyContent='flex-end'>
                            <Grid item xs={2}></Grid>
                            <Grid
                              container
                              item
                              direction='column'
                              className={classes.clipsAnswerContainer}
                              xs
                            >
                              <Grid item>
                                <div className={classes.clipsAnswerThumbnailContainer}>
                                  <img
                                    src={qs.clipsCreatorItem?.thumbnail}
                                    alt='thumbnail'
                                    className={classes.clipsAnswerThumbnail}
                                  />
                                  <div className={classes.clipsPlayButtonContainer}>
                                    <ClipsPlayButton onClick={() => showClipsVideo(qs)} isLarge />
                                  </div>
                                </div>
                              </Grid>
                              <Grid item className={classes.clipsAnswerThumbnailTitle}>
                                <span>{qs.clipsCreatorItem.title}</span>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    ) : null}
                  </Fragment>
                );
              })}
              {selectedQuestion?.has_feedback && (
                <Grid container className={classes.questionContainer}>
                  <Grid item className={classes.questionInnerContainer}>
                    {/* name/date */}
                    <Grid container justifyContent='space-between' alignItems='center'>
                      <Grid item>
                        <div className={classes.customerName}>
                          {displayCustomerName(selectedQuestion)}
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={classes.questionDate}>
                          {displayLastActionTime(selectedQuestion.last_submission_time)}
                        </div>
                      </Grid>
                    </Grid>
                    {/* question title */}
                    <Grid container className={classes.questionTitleContainer}>
                      <Grid item xs={12}>
                        <div className={classes.customerMessage}>{selectedQuestion.feedback}</div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {!!questionSession && isLastQuestionAnswered && !selectedQuestion?.has_feedback && (
                <Grid
                  item
                  container
                  direction='column'
                  alignItems='center'
                  className={classes.answerSentContainer}
                >
                  <Grid item>
                    <PaperPlaneIcon width={30} height={30} fillColor={grey[500]} />
                  </Grid>
                  <Grid item>
                    <div className={classes.answerSentText}>
                      Your answer has been sent to {selectedQuestion?.customer_name}.
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
          </Grid>
          {/* footer */}
          {!isLastQuestionAnswered && (
            <Grid item container className={classes.textareaSectionOuterContainer}>
              <form onSubmit={handleSubmit} className={classes.form}>
                {/* first draft textarea section */}
                <Slide direction='up' in={firstDraftInputOpen} mountOnEnter unmountOnExit>
                  <Grid
                    item
                    xs={12}
                    className={`${classes.textareaSectionInnerContainer} withBorder`}
                  >
                    {/* Textarea section */}
                    <Grid container className={classes.textareaSectionContainer}>
                      {copilotAnswerIsLoading && (
                        <div className={classes.actionLoader}>
                          <CircularProgress color='inherit' thickness={3} size={30} />
                        </div>
                      )}
                      <Grid item xs={1}>
                        <FirstDraftActionIcon className={classes.firstDraftIcon} />
                      </Grid>
                      {/* textarea */}
                      <Grid item xs={10}>
                        <TextField
                          classes={{
                            root: `${classes.textField} textArea firstDraft ${
                              values.firstDraft_prompt.length < 200 ? 'initHeight' : ''
                            }`,
                          }}
                          variant='filled'
                          fullWidth
                          placeholder='Type your question'
                          multiline
                          autoFocus
                          disabled={!!copilotAnswer}
                          minRows={1}
                          maxRows={4}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          {...getFieldProps('firstDraft_prompt')}
                          onBlur={handleTrimOnBlur}
                        />
                      </Grid>
                      {/* actions */}
                      <Grid item xs>
                        <Grid
                          container
                          direction='column'
                          alignItems='center'
                          justifyContent='center'
                        >
                          <Grid item>
                            <CircleErrorIcon
                              width={19}
                              height={19}
                              fillColor={grey.A700}
                              className={`${classes.actionButton} spaceTop`}
                              onClick={() => {
                                setFirstDraftInputOpen(false);
                                resetGetCopilotAnswer();
                                handleChange({
                                  target: {
                                    name: 'firstDraft_prompt',
                                    value: '',
                                  },
                                });
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <RevertIcon
                              className={`${classes.actionButton} spaceHalfTop`}
                              fillColor={!!copilotAnswer ? blue : grey.A700}
                              onClick={() => {
                                if (!copilotAnswer) return;
                                resetGetCopilotAnswer();
                                handleChange({
                                  target: {
                                    name: 'firstDraft_prompt',
                                    value: '',
                                  },
                                });
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <FirstDraftSelectIcon {...firstDraftSelectProps} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Slide>

                {/* answer textarea section */}
                <Grid
                  item
                  xs={12}
                  className={`${classes.textareaSectionInnerContainer} ${
                    firstDraftInputOpen ? 'withoutBorder' : 'withBorder'
                  }`}
                >
                  {/* Textarea section */}
                  <Grid container className={classes.textareaSectionContainer}>
                    {/* attachments */}
                    {(!!values.attachment_image_url ||
                      !!values.attachment_video_url ||
                      (currQuestionSession.clipsCreatorItem && useClip)) && (
                      <>
                        <Fade in>
                          <Grid container className={classes.attachmentContainer} spacing={1}>
                            {currQuestionSession.clipsCreatorItem && useClip && (
                              <Grid item>
                                <div className={classes.clipsVideoThumbnail}>
                                  <img
                                    src={currQuestionSession.clipsCreatorItem?.thumbnail}
                                    alt='thumbnail'
                                  />
                                  <div className={classes.clipsPlayButtonContainer}>
                                    <ClipsPlayButton
                                      onClick={() => showClipsVideo(currQuestionSession)}
                                    />
                                  </div>
                                  <div className={classes.clipsCloseButtonContainer}>
                                    <ClipsCloseButton onClick={() => setUseClip(false)} />
                                  </div>
                                </div>
                              </Grid>
                            )}
                            {(!!values.attachment_image_url || !!values.attachment_video_url) && (
                              <Grid item>
                                <div className={classes.attachmentInputContainer}>
                                  <div className={classes.clipsCloseButtonContainer}>
                                    <ClipsCloseButton onClick={removeFormAttachments} />
                                  </div>
                                  <a
                                    href={
                                      values.attachment_image_url || values.attachment_video_url
                                    }
                                    target='_blank'
                                    rel='noreferrer'
                                    className={`${classes.customerAttachment} large`}
                                    style={{
                                      backgroundImage: `url(${
                                        values.attachment_image_url || thumbnailVideoUrl
                                      })`,
                                      backgroundPosition: 'center',
                                      backgroundSize: 'cover',
                                    }}
                                  />
                                </div>
                              </Grid>
                            )}
                          </Grid>
                        </Fade>
                        <Grid item xs={12} className={classes.divider}></Grid>
                      </>
                    )}
                    {/* textarea */}
                    <Grid item xs={12}>
                      <TextField
                        inputRef={(el) => (textAreaRef.current = el)}
                        classes={{
                          root: `${classes.textField} textArea ${classes.firstDraftAnswer}`,
                        }}
                        variant='filled'
                        fullWidth
                        placeholder={`Answer ${displayCustomerName(selectedQuestion)}`}
                        multiline
                        maxRows={mobileType ? 10 : 20}
                        {...getFieldProps('creator_message')}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: firstDraftAnswerClicked ? '' : classes.unclicked,
                          },
                        }}
                        onBlur={handleTrimOnBlur}
                        onClick={handleAnswerTextClick}
                        onInput={handleAnswerTextInput}
                      />
                      {!firstDraftAnswerClicked && (
                        <ClipsCloseButton
                          extraClass={classes.clearMsgBtn}
                          onClick={handleClearMsg}
                        />
                      )}
                    </Grid>
                    {/* actions */}
                    <Grid
                      item
                      container
                      justifyContent='space-between'
                      alignItems='flex-end'
                      className={classes.textAreaActionContainer}
                    >
                      <Grid item>
                        <Grid container alignItems='center' spacing={2}>
                          {/* attachments */}
                          <Grid item {...(!firstDraftAnswerClicked ? null : rootAttachmentProps())}>
                            <div className={classes.attachmentInputContainer}>
                              {attachmentUploadIsLoading && (
                                <div className={classes.actionLoader}>
                                  <CircularProgress color='inherit' thickness={3} size={15} />
                                </div>
                              )}
                              <AttachmentActionIcon
                                fillColor={grey.A700}
                                className={`${classes.actionButton} ${firstDraftAnswerClicked ? '' : 'disabled'}`}
                              />
                              <input {...inputAttachmentProps()} />
                            </div>
                          </Grid>
                          {/* firstdraft */}
                          <Grid item>
                            <FirstDraftActionIcon
                              fillColor={grey.A700}
                              className={`${classes.actionButton} ${
                                firstDraftInputOpen || !firstDraftAnswerClicked ? 'disabled' : ''
                              }`}
                              onClick={() => {
                                if (!firstDraftAnswerClicked || firstDraftInputOpen) return;

                                setFirstDraftInputOpen(true);
                                window.scrollTo(0, 9999);
                                handleChange({
                                  target: {
                                    name: 'firstDraft_prompt',
                                    value: '',
                                  },
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* send */}
                      <Grid item>
                        <PaperPlaneIcon
                          width={21}
                          height={21}
                          fillColor={canSubmitAnswer ? blue : grey.A700}
                          className={`${classes.actionButton} ${canSubmitAnswer ? '' : 'disabled'}`}
                          onClick={() => {
                            if (!canSubmitAnswer) return;

                            onSendAnswerToAudience({
                              answer_verified: true,
                              question_tracking_code: lastQuestionTrackingCode,
                              message: values.creator_message,
                              show_clips: useClip && currQuestionSession.clipsCreatorItem != null,
                              image_urls: values.attachment_image_name
                                ? [values.attachment_image_name]
                                : [],
                              video_urls: values.attachment_video_name
                                ? [values.attachment_video_name]
                                : [],
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          )}
        </Grid>
      </Drawer>
      <ClipsVideoPlayerDialog
        video={clipsVideo}
        offsetOnDesktop
        onClose={() => setClipsVideo(null)}
      />
    </>
  );
};

export default QuestionSessionDrawer;
