import { number, object, string } from 'prop-types';

const QuestionPassIcon = ({
  className = '',
  style = {},
  width = 26,
  height = 22,
  fillColor = '#C1C1C1',
  ...rest
}) => (
  <svg
    {...rest}
    className={className}
    style={style}
    width={width}
    height={height}
    viewBox="13.31 13.31 1173.38 1173.38"
    fill={fillColor}
  >
    <path d="m600 13.312c-323.63 0-586.69 263.06-586.69 586.69s263.02 586.69 586.69 586.69c323.63 0 586.69-263.02 586.69-586.69 0-323.63-263.06-586.69-586.69-586.69zm-416.95 586.69c0-83.625 25.453-162.42 67.875-227.86l576.98 576.98c-65.438 42.422-144.24 67.875-227.86 67.875-230.34-0.046874-417-186.71-417-417zm764.81 229.08-576.94-576.94c65.438-43.641 144.24-69.094 229.08-69.094 230.29 0 416.95 186.66 416.95 416.95 0 84.844-25.453 163.64-69.094 229.08z" />
  </svg>
);

QuestionPassIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  fillColor: string,
  strokeColor: string,
};

export default QuestionPassIcon;
