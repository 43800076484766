const Video = ({
  ...rest
}) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    version="1.1" 
    viewBox="0 116.67 700 466.66"
    {...rest}
  >
    <path d="m641.67 525-145.83-58.332v58.332h-0.003907c0 15.473-6.1445 30.309-17.086 41.246-10.938 10.941-25.773 17.086-41.246 17.086h-379.17c-15.469 0-30.309-6.1445-41.246-17.086-10.941-10.938-17.086-25.773-17.086-41.246v-350c0-15.473 6.1445-30.309 17.086-41.246 10.938-10.941 25.777-17.086 41.246-17.086h379.17c15.473 0 30.309 6.1445 41.246 17.086 10.941 10.938 17.086 25.773 17.086 41.246v58.332l145.83-58.332h0.003907c15.469 0 30.309 6.1445 41.246 17.086 10.941 10.938 17.086 25.777 17.086 41.246v233.34c0 15.469-6.1445 30.309-17.086 41.246-10.938 10.941-25.777 17.086-41.246 17.086zm-204.17-116.67v-233.33h-379.17v350h379.17zm204.17-175-145.83 58.332v116.67l145.83 58.332z" fillRule="evenodd"/>
  </svg>
);

export default Video;