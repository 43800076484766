import {
  InboxFacebookIcon,
  InboxInstagramIcon,
  InboxLinkedinIcon,
  InboxLinktreeIcon,
  InboxPatreonIcon,
  InboxTiktokIcon,
  InboxVideoIcon,
  InboxYoutubeIcon,
} from 'components/Icons';

const thumbnailVideoUrl = 'https://asqme-dev-app.s3.us-west-1.amazonaws.com/thunbnail-video.png';

const statusTabTypes = {
  all: 'all',
  answered: 'answered',
  unanswered: 'unanswered',
  favorites: 'favorites',
  pass: 'pass'
};

const paginationTabTypes = {
  20: '20',
  50: '50',
  all: 'all',
};

const initDisplayOptions = {
  activeTab: statusTabTypes.all,
  paginationTab: paginationTabTypes.all,
  page: 1,
  showPassed: false,
};

const socialMediaLinks = [
  {
    icon: InboxVideoIcon,
    text: `In your videos, frequently remind your audience to "Use my AsqMe link for fast, direct answers`,
    link: 'https://help.asqme.com/en/articles/8843949-engaging-your-youtube-audience-with-custom-intros',
    linkText: 'Learn More',
    mobileEnabled: false,
  },
  {
    icon: InboxYoutubeIcon,
    text: `On YouTube, add your AsqMe link to your channel, about links, and all your video descriptions`,
    link: 'https://www.youtube.com',
    linkText: 'Add to YouTube',
    mobileEnabled: true,
  },
  {
    icon: InboxTiktokIcon,
    text: `On TikTok, select Edit Profile and paste your AsqMe link in the website field`,
    link: 'https://www.tiktok.com',
    linkText: 'Add to TikTok',
    mobileEnabled: false,
  },
  {
    icon: InboxLinktreeIcon,
    text: `On Linktree, hit the + Add link button, paste your AsqMe link, and give it a title`,
    link: 'https://linktr.ee/admin',
    linkText: 'Add to Linktree',
    mobileEnabled: true,
  },
  {
    icon: InboxInstagramIcon,
    text: `On Instagram, select Edit Profile and paste your AsqMe link in the website field`,
    link: 'https://www.instagram.com/accounts/edit/',
    linkText: 'Add to Instagram',
    mobileEnabled: true,
  },
  {
    icon: InboxFacebookIcon,
    text: `On Facebook, tap About under your profile image and paste your AsqMe link in the website field`,
    link: 'https://www.facebook.com',
    linkText: 'Add to Facebook',
    mobileEnabled: false,
  },
  {
    icon: InboxLinkedinIcon,
    text: `On LinkedIn, tap Me, then select View profile and add your AsqMe link to website section`,
    link: 'https://www.linkedin.com/',
    linkText: 'Add to LinkedIn',
    mobileEnabled: true,
  },
  {
    icon: InboxPatreonIcon,
    text: `On Patreon, tap "Describe your page" in MyPage and add you AsqMe link and description in your bio`,
    link: 'https://www.patreon.com/',
    linkText: 'Add to Patreon',
    mobileEnabled: false,
  },
];

export {
  statusTabTypes,
  socialMediaLinks,
  paginationTabTypes,
  initDisplayOptions,
  thumbnailVideoUrl,
};
