import { useEffect, useState } from 'react';
import { ButtonBase, Checkbox, Grid, Slide, useMediaQuery, useTheme  } from '@material-ui/core';
import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import { makeStyles } from '@material-ui/core';
import { WarningIcon } from 'components/Icons';
import { defaultAlertError, questionPassReasons, questionPassOptions } from 'utils/common/constants';
import { questionPass } from 'store/creator';
import { useMutation } from '@tanstack/react-query';
import { updateMessenger } from 'utils/intercomConfig';

const useStyles = makeStyles((theme) => ({
  slideWrapper: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    zIndex: 2000,
    bottom: 0,
  },
  slideContainer: {
    backgroundColor: theme.common.black,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    padding: '20px 40px',
    marginLeft: 176, // Nav offset
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      padding: 20,
      marginLeft: 0, // No offset on mobile
    },
  },
  textContainer: {
    padding: '0 100px',
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  heading: {
    textAlign: 'center',
    color: theme.common.white,
    fontFamily: 'Figtree-Bold',
    fontSize: 20,
    paddingBottom: 2,
  },
  message: {
    lineHeight: 1,
    textAlign: 'center',
    color: theme.common.white,
    fontSize: 14,
    padding: '0 10px',
  },
  select: {
    padding: '0 40px',
    marginTop: 20,
    width: '100%',
    '& .select__control--is-focused': {
      borderColor: '#495EFF',
      boxShadow: '0 0 0 1px #495EFF',
    },
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  checkboxContainer: {
    marginTop: 4,
    padding: '0 37px', // -3px for the checkbox padding
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  checkbox: {
    padding: 0,
    color: 'white',
    '&.Mui-checked': {
      color: 'white',
    },
  },
  checkboxLabel: {
    alignContent: 'center',
    '& span': {
      fontFamily: 'Figtree-Regular',
      color: 'white',
      fontSize: 14,
    },
  },
  btnContainer: {
    marginTop: 20,
  },
  cancelButton: {
    height: 44,
    borderRadius: 5,
    padding: '0 20px',
    border: '1px solid white',
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
    color: 'white',
    '&:hover': {
      color: 'white !important',
    },
    width: '100%',
  },
  passButton: {
    height: 44,
    padding: '0 20px',
    borderRadius: 5,
    backgroundColor: '#495EFF',
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
    color: 'white',
    '&.disabled': {
      backgroundColor: theme.grey[500],
      cursor: 'not-allowed',
    },
  }
}));

const QuestionPassSlide = (props) => {
  const { open, onClose, questionInfo, onQuestionPassed } = props;

  const classes = useStyles();
  const { breakpoints } = useTheme();
  const mobileView = useMediaQuery(breakpoints.down('xs'));

  const alert = useAlert();

  const headingText = 'No problem! Why are you passing?';
  const messageText = questionInfo?.payment_mode === 'PRIORITY'
    ? 'We’ll let them know and they will not be charged.'
    : 'We’ve got your back and will take it from here.';

  const optionsPlaceholderText = 'Please select the reason you\'re not answering the question';

  const [isFormValid, setIsFormValid] = useState(false);

  const { mutate: onQuestionPass, isLoading: questionPassLoading } = useMutation({
    mutationFn: questionPass,
    onSuccess: onQuestionPassed,
    onError: (err) => {
      alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  const {
    getFieldProps,
    handleChange,
    handleSubmit,
    resetForm,
    validateForm,
    values,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: undefined,
      ban_user: false,
    },
    onSubmit: (data) => {
      const reason = data.reason?.value;
      const banUser = data.ban_user ?? false;
      if (reason) {
        onQuestionPass({ id: questionInfo.id, reason: reason, ban_user: banUser });
      }
    },
  })

  useEffect(() => {
    if (!open) {
      resetForm();
      setIsFormValid(false);
    }
  }, [open, resetForm, validateForm]);

  useEffect(() => {
    if (!mobileView) return;

    // Hide the intercom launcher (blue chat bubble) when the question
    // slide is open on mobile. This is to prevent it from blocking the "pass" button.
    updateMessenger({ hide_default_launcher: open });

    return () => {
      updateMessenger({ hide_default_launcher: false });
    }
  }, [open, mobileView])

  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <div className={classes.slideWrapper}>
        <div className={`${classes.slideContainer} offset`}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction='column'
              alignItems='center'
              justifyContent='center'
              className={classes.contentContainer}
            >
              <Grid item>
                  <WarningIcon />
              </Grid>
              <Grid item className={classes.textContainer}>
                <div className={classes.heading}>{headingText}</div>
                <div className={classes.message}>{messageText}</div>
              </Grid>
              <Grid item className={classes.select}>
                <Select
                  classNamePrefix='select'
                  placeholder={optionsPlaceholderText}
                  value={values.reason}
                  isClearable={false}
                  isMulti={false}
                  isSearchable={false}
                  options={questionPassOptions}
                  menuPlacement='top'
                  {...getFieldProps('reason')}
                  onChange={(option) => {
                    setIsFormValid(true);
                    handleChange({
                      target: { name: 'reason', value: option },
                    });
                  }}
                />
              </Grid>
              {values.reason?.value === questionPassReasons.offensiveContent && (
                <Grid item container direction='row' justifyContent='flex-start' spacing={1} className={classes.checkboxContainer}>
                  <Grid item>
                    <Checkbox
                      className={classes.checkbox}
                      color='primary'
                      {...getFieldProps('ban_user')}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item className={classes.checkboxLabel}>
                    <span>Report and block user</span>
                  </Grid>
                </Grid>
              )}
              <Grid 
                container 
                item 
                direction='row' 
                justifyContent='flex-end'
                className={classes.btnContainer}
                spacing={2}
              >
                <Grid item>
                  <ButtonBase 
                    className={classes.cancelButton}
                    onClick={onClose}
                  >
                    Cancel
                  </ButtonBase>
                </Grid>
                <Grid item>
                  <ButtonBase 
                    className={`${classes.passButton} ${!isFormValid || questionPassLoading ? 'disabled' : ''}`}
                    disabled={!isFormValid || questionPassLoading}
                    type='submit'
                  >
                    Pass on Question
                  </ButtonBase>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </Slide>
  );
}

export default QuestionPassSlide;
