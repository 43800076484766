import { methods, fetchApi } from 'utils/apiConfig';

const { GET, POST, PATCH } = methods;

const queryCreatorTypes = {
  audienceQuestion: 'audienceQuestion',
  balance: 'balance',
  countries: 'countries',
  inbox: 'inbox',
  getQuestionDetails: 'getQuestionDetails',
};

// authorized api calls
const getInbox = ({ signal }) => fetchApi('/qa_hub/getAllQuestionSessions', GET, { signal });

const getQuestionDetails = ({ queryKey: [_, sessionTrackingCode], signal }) =>
  fetchApi(`qa_hub/getSessionTimeline/${sessionTrackingCode}`, GET, { signal });

const toggleFavoriteQuestion = (data) =>
  fetchApi('/qa_hub/toggle_session_is_favorite', PATCH, { data });

const toggleHiddenQuestion = (data) =>
  fetchApi('/qa_hub/toggle_session_is_hidden', PATCH, { data });

const questionPass = (data) =>
  fetchApi('/qa_hub/question_pass', POST, { data });

const getBalance = ({ signal }) =>
  fetchApi('/creator/getBalance', GET, {
    signal,
  });

const getSupportedCountries = () => fetchApi('/creator/getSupportedCountries', GET);

const withdraw = () => fetchApi('/creator/withdraw', POST);

const getAuthCopilotAnswer = (params) =>
  fetchApi('/creator/askGPT', GET, {
    timeout: 0,
    params,
  });

const updateTipJarFeature = (data) => fetchApi('/creator/enableTipJar', PATCH, { data });

const updateThankBankSettings = (data) => fetchApi('/creator/updateThankBank', PATCH, { data });

const updateWeeklyDashboardEmailSetting = (data) => fetchApi('/creator/updateWeeklyDashboardEmailSetting', PATCH, { data });

const trackTipJar = (data) =>
  fetchApi('/segment-service/send_track_call_tc_s5_Pop_Up_Tips', POST, { data });

const trackDontCharge = (data) =>
  fetchApi('segment-service/send_track_call_tc_s6_Pop_Up_Dont_Charge', POST, { data });

const trackTooltip = (data) =>
  fetchApi('/segment-service/send_track_call_tc_s7_Pop_Up_Tool_Tips', POST, { data });

const trackAppCtaClick = (data) =>
  fetchApi('/creator/ctaClickTrackCall', POST, { data });

// unauthorized api calls
const getAudienceQuestion = ({ signal, queryKey: [_, code] }) =>
  fetchApi(`/creator/getSessionCopilot/${code}`, GET, {
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    signal,
  });

const getCopilotAnswer = ({ questionTrackingCode, ...params }) =>
  fetchApi(`/creator/getGPTanswer/${questionTrackingCode}`, GET, {
    timeout: 0,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    params,
  });

const sendAnswerToAudience = (data) =>
  fetchApi('/creator/saveAnswer', POST, {
    timeout: 0,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    data,
  });

const enableClips = (data) =>
  fetchApi('/clips/enable', POST, {
    timeout: 0,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    data,
  });

const disconnectClips = () =>
  fetchApi('/clips/disconnect', POST, {
    timeout: 0,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
  });

const firstDraftChatInit = () =>
  fetchApi('/first-draft/chat-init', POST, {
    timeout: 0,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
  });

const firstDraftChatQuestion = (data) =>
  fetchApi('/first-draft/chat-question', POST, {
    timeout: 0,
    headers: {
      chinawall: process.env.REACT_APP_CHINA_WALL,
    },
    data,
  });

const getCustomerExportCsv = (params) => fetchApi(`/creator/getCustomerExportCsv?mostRecent=${params?.mostRecent ?? false}`, GET, { responseType: 'blob' }, false);

export {
  queryCreatorTypes,
  getInbox,
  getQuestionDetails,
  getBalance,
  getSupportedCountries,
  withdraw,
  getAuthCopilotAnswer,
  updateTipJarFeature,
  updateThankBankSettings,
  updateWeeklyDashboardEmailSetting,
  getAudienceQuestion,
  getCopilotAnswer,
  sendAnswerToAudience,
  toggleFavoriteQuestion,
  toggleHiddenQuestion,
  questionPass,
  trackDontCharge,
  trackTipJar,
  trackTooltip,
  trackAppCtaClick,
  enableClips,
  disconnectClips,
  firstDraftChatInit,
  firstDraftChatQuestion,
  getCustomerExportCsv,
};
