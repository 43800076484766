const Trashcan = ({
  ...rest
}) => (
  <svg 
    width="16" 
    height="18" 
    viewBox="0 0 16 18" 
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M6.61538 -0.021637C6.03666 -0.021637 5.46875 0.0919451 5.05769 0.497594C4.64663 0.903243 4.51682 1.47926 4.51682 2.07692H1.76923C1.38792 2.07692 1.07692 2.38792 1.07692 2.76923H0.384613V4.15384H15.6154V2.76923H14.9231C14.9231 2.38792 14.6121 2.07692 14.2308 2.07692H11.4832C11.4832 1.47926 11.3534 0.903243 10.9423 0.497594C10.5312 0.0919451 9.96334 -0.021637 9.38461 -0.021637H6.61538ZM6.61538 1.40625H9.38461C9.76322 1.40625 9.88221 1.49549 9.92548 1.53606C9.96875 1.57662 10.0553 1.6902 10.0553 2.07692H5.94471C5.94471 1.6902 6.03125 1.57662 6.07452 1.53606C6.11779 1.49549 6.23678 1.40625 6.61538 1.40625ZM1.76923 4.84615V15.9231C1.76923 17.067 2.70222 18 3.84615 18H12.1538C13.2978 18 14.2308 17.067 14.2308 15.9231V4.84615H1.76923ZM4.53846 6.92307H5.92307V15.2308H4.53846V6.92307ZM7.30769 6.92307H8.69231V15.2308H7.30769V6.92307ZM10.0769 6.92307H11.4615V15.2308H10.0769V6.92307Z"/>
  </svg>
);

export default Trashcan;